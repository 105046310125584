<template>
    <div class="circle_outer" @click="handleClick">
        <div class="circle_inner" :class="getInnerClass()">
            <template v-if="$slots.default">
                <slot></slot>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        innerClass: String,
    },
    methods: {
        handleClick(){
            this.$emit("click");
        },
        getInnerClass(){
            const classes = [this.innerClass];
            if(this.disabled){
                classes.push('btn_disabled');
            }
            return classes.join(" ");
        }
    }
}
</script>

<style lang="less" scoped>
.circle_outer {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    border-radius: 50%;
    background-color: rgb(196, 217, 255, 0.7);
    margin-right: 8px;
}
.circle_inner {
    width: 50px;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
    border-radius: 50%;
    background-color: #539cfe;
}
.btn_disabled {
    cursor: not-allowed;
    opacity: .5;
}
.btn_gray {
    background-color: rgb(196, 217, 255, 0.9);
}
</style>