import { UAParser } from 'ua-parser-js';

const DEVICE_TYPES = {
    MOBILE: "mobile",
    TABLET: "tablet",
};

const OS_TYPES = {
    IOS: "iOS",
    ANDROID: "Android",
    HARMONYOS: "HarmonyOS",
    WINDOWS: "Windows",
    MAC_OS: "Mac OS"
}; 

const parser = new UAParser();
const device = parser.getDevice();
const os = parser.getOS();
const ua = parser.getUA();
const parserResult = parser.getResult();

const isMobile = [DEVICE_TYPES.MOBILE, DEVICE_TYPES.TABLET].includes(device.type);
const isAndroid = os.name === OS_TYPES.ANDROID;
const isHarmonyOS = os.name === OS_TYPES.HARMONYOS;

function mobileDetect(){
    if(isMobile){
        const vendor = device.vendor || "unknown";
        const model = device.model || "unknown";
        return {
            vendor: vendor?.toLowerCase(),
            model: model.toLowerCase(),
            browser: parserResult.browser?.name,
            os: os.name,
            ua: ua,
        }
    }
}

export default {
    device,
    isMobile,
    isAndroid,
    isHarmonyOS,
    mobileDetect,
    parserResult,
}